import { createAction } from 'redux-actions';

export const CHAT_INITIALIZE_PUBLIC = 'chat/public/INITIALIZE';
export const CHAT_INITIALIZING_PUBLIC = 'chat/public/INITIALIZING';
export const CHAT_READY_PUBLIC = 'chat/public/READY';
export const CHAT_INIT_FAILED_PUBLIC = 'chat/public/INIT_FAILED';
export const CHAT_MARK_AS_READ_PUBLIC = 'chat/public/MARK_AS_READ';
export const CHAT_ADD_MESSAGE_PUBLIC = 'chat/public/ADD_MESSAGE';
export const CHAT_MESSAGE_RECEIVED_PUBLIC = 'chat/public/MESSAGE_RECEIVED';
export const CHAT_SEND_MESSAGE_PUBLIC = 'chat/public/SEND_MESSAGE';
export const CHAT_SEND_ATTACHMENT_PUBLIC = 'chat/public/SEND_ATTACHMENT';
export const CHAT_ATTACHMENT_SENT_PUBLIC = 'chat/public/ATTACHMENT_SENT';
export const CHAT_EXIT_PUBLIC = 'chat/public/EXIT';
export const CHAT_DATA_SHARE_PROMPT_ANSWERED_PUBLIC = 'chat/public/DATA_SHARE_PROMPT_ANSWERED';
export const CHAT_GROUP_UPDATED_PUBLIC = 'chat/public/GROUP_UPDATED';
export const CHAT_MEDIA_UPLOADED_PUBLIC = 'chat/public/MEDIA_UPLOADED';
export const CHAT_MEDIA_SENT_PUBLIC = 'chat/public/MEDIA_SENT';
export const CHAT_MEDIA_UPLOAD_PROGRESS_PUBLIC = 'chat/public/MEDIA_UPLOADED_PROGRESS';

export const SENDBIRD_CONNECTED_PUBLIC = 'SENDBIRD_PUBLIC_CONNECTED';
export const SENDBIRD_RECONNECT_PUBLIC = 'SENDBIRD_PUBLIC_RECONNECT';
export const SENDBIRD_RECONNECTING_PUBLIC = 'SENDBIRD_PUBLIC_RECONNECTING';
export const SENDBIRD_CONNECTING_PUBLIC = 'SENDBIRD_PUBLIC_CONNECTING';
export const SENDBIRD_CONNECT_FAILED_PUBLIC = 'SENDBIRD_PUBLIC_CONNECT_FAILED';
export const SENDBIRD_CHANNELS_FETCHED_PUBLIC = 'sendbird/public/CHANNELS_FETCHED';

const createActionWrapper = action => {
  return createAction(
    action,
    data => data.payload,
    data => data.meta
  );
};

export const publicChatActionCreators = {
  initChat: createActionWrapper(CHAT_INITIALIZE_PUBLIC),
  sendMessage: createActionWrapper(CHAT_SEND_MESSAGE_PUBLIC),
  exitChat: createAction(CHAT_EXIT_PUBLIC),
  dataSharingPromptAnswered: createActionWrapper(CHAT_DATA_SHARE_PROMPT_ANSWERED_PUBLIC),
  chatGroupUpdated: createAction(CHAT_GROUP_UPDATED_PUBLIC),
};
