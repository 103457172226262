import Sendbird from 'sendbird';
import SendbirdChat from '@sendbird/chat';
import { GroupChannelFilter, GroupChannelListOrder, GroupChannelModule } from '@sendbird/chat/groupChannel';
import { channel as sagaChannel, eventChannel } from 'redux-saga';
import { call, cancel, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import * as ConversationService from 'services/conversation/conversation.service';
import { S3MediaManager } from 'lib/s3MediaManager';
import { SendBirdAction, SendBirdChatEvent, getSendbirdAppId, uuid4 } from 'lib/sendbird';
import { getUserInfo } from 'utils';

import {
  CHAT_ADD_MESSAGE_PUBLIC,
  CHAT_EXIT_PUBLIC,
  CHAT_INITIALIZE_PUBLIC,
  CHAT_INITIALIZING_PUBLIC,
  CHAT_INIT_FAILED_PUBLIC,
  CHAT_MARK_AS_READ_PUBLIC,
  CHAT_MEDIA_SENT_PUBLIC,
  CHAT_MEDIA_UPLOADED_PUBLIC,
  CHAT_MEDIA_UPLOAD_PROGRESS_PUBLIC,
  CHAT_MESSAGE_RECEIVED_PUBLIC,
  CHAT_READY_PUBLIC,
  CHAT_SEND_ATTACHMENT_PUBLIC,
  CHAT_SEND_MESSAGE_PUBLIC,
  SENDBIRD_CONNECTING_PUBLIC,
  SENDBIRD_RECONNECT_PUBLIC,
  SENDBIRD_RECONNECTING_PUBLIC,
  SENDBIRD_CHANNELS_FETCHED_PUBLIC,
  SENDBIRD_CONNECTED_PUBLIC,
} from './actions';
import { USER_INITIAL_SUCCESSFUL } from '../auth/actions';

import { IAction } from 'redux/store/types';
import { IGiftedMessage } from './types';

const connectionStatus = {
  connecting: 0,
  connected: 1,
  fetchingMessages: 2,
  promptRequired: 3,
  readyToChat: 4,
  failedToConnect: 5,
  closed: 6,
};

let sbChat;
const callbackChannel = sagaChannel();

/**
 * @Name getSBChannel
 * @param channelUrl , userId
 * @description This method is used to get sendBird channel
 */
function* getSBChannel(channelUrl: unknown, userId: { connectionId: string }) {
  const sendBirdAction = yield call(SendBirdAction.getInstance);
  let sendBirdChannel = null;
  if (channelUrl) {
    sendBirdChannel = yield call(sendBirdAction.getChannel, channelUrl, false);
  } else {
    console.log('No Channel URL present in cache. Getting Channel URL from Backend');
    const pathParams = { connectionId: userId.connectionId ?? (channelUrl as string) };
    const channelIdResponse = yield call(ConversationService.getChannelUrl, pathParams);

    if (channelIdResponse.errors || channelIdResponse.data.channelUrl === null) {
      console.log(channelIdResponse.errors);
      yield put({
        type: CHAT_INITIALIZING_PUBLIC,
        payload: {
          connectionStatus: connectionStatus.failedToConnect,
        },
      });
      yield put({
        type: CHAT_EXIT_PUBLIC,
      });
    } else {
      sendBirdChannel = yield call(sendBirdAction.getChannel, channelIdResponse.data.channelUrl, false);
    }
  }

  return sendBirdChannel;
}

const createChatEventChannel = (channelEvent: {
  onMessageReceived: (channel: { url: any; isDistinct: any }, message: any) => void;
  onMessageUpdated: (channel: any, message: any) => void;
}) => {
  return eventChannel(emit => {
    channelEvent.onMessageReceived = (sbChannel: { url: any; isDistinct: any }, message: any) => {
      emit({
        message,
        channel: sbChannel,
        channelUrl: sbChannel.url,
        type: 'MessageReceived',
        isDistinct: sbChannel.isDistinct,
      });
    };
    channelEvent.onMessageUpdated = (channel, message) => {
      console.log('onMessageUpdated', channel, message);
    };
    return () => {
      // connectionStatusStream.unsubscribe();
    };
  });
};

function* sendBirdFileSender(
  sbAction: { sendFileMessage: any },
  payload: {
    _id: any;
    location: string;
    type: string;
    nickName: string;
    user: any;
    parentMessageId: number;
    data: any;
  },
  channel: { url: any },
  connection: any,
  dispatch: (arg0: {
    type: string;
    payload: {
      _id: any;
      channelUrl: any;
      location: any;
      message: any;
      messageId: any;
      type: string;
      user: any;
      prevMsg: any;
      msgCreatedAt: any;
    };
    meta: { nickName: any; contact: { provider: any } };
  }) => void
) {
  const handlerCB = (message: any, error: any) => {
    if (message) {
      console.log('Sendbird media sent', message);
      dispatch({
        type: CHAT_MEDIA_SENT_PUBLIC,
        payload: {
          _id: payload._id,
          user: payload.user,
          channelUrl: channel.url,
          location: payload.location,
          messageId: message.messageId,
          type: payload.type,
          prevMsg: payload.data,
          message,
          msgCreatedAt: message?.createdAt,
        },
        meta: {
          nickName: payload.nickName,
          contact: {
            provider: connection,
          },
        },
      });
    } else {
      console.log('Sendbird error sending media');
      console.log(error);
    }
  };
  try {
    const sb = Sendbird.getInstance();
    const params = new sb.FileMessageParams();
    params.parentMessageId = payload.parentMessageId;
    params.fileUrl = payload.location;
    params.mimeType = payload.type;
    params.data =
      payload?.data?.messageId === undefined
        ? JSON.stringify({})
        : payload.data.messageId !== 0
        ? JSON.stringify(payload.data)
        : JSON.stringify({});
    const requestPayload = {
      channel,
      file: params,
      handler: handlerCB,
    };
    yield call(sbAction.sendFileMessage, requestPayload);
  } catch (e) {
    console.log('Sendbird error sending media');
    console.log(e);
  }
}

function* mediaSenderTask(
  sendBirdAction: { sendFileMessage: any },
  connectedChannel: { url: any },
  dispatch: (arg0: {
    type: string;
    payload: { _id: any; channelUrl: any; location: any };
    meta: { contact: { provider: any } };
  }) => void,
  connection: any
) {
  while (true) {
    const { payload } = yield take(CHAT_MEDIA_UPLOADED_PUBLIC);
    yield fork(sendBirdFileSender, sendBirdAction, payload, connectedChannel, connection, dispatch);
  }
}

function* awsMediaUploader(
  payload: { _id: any; file: any; channel: any; nickName: any; user: any; parentMessageId: any; data: any },
  dispatch: (arg0: { type: string; payload: { _id: any; file: any; channel: any; progress: number } }) => void
) {
  try {
    const response = yield call(S3MediaManager.uploadChatMedia, payload.file, e => {
      const progress = e.percent * 100;
      dispatch({
        type: CHAT_MEDIA_UPLOAD_PROGRESS_PUBLIC,
        payload: {
          ...payload,
          progress,
        },
      });
    });
    if (response.success) {
      yield put({
        type: CHAT_MEDIA_UPLOADED_PUBLIC,
        payload: {
          user: payload.user,
          nickName: payload.nickName,
          channelUrl: payload.channel.channelUrl,
          _id: payload._id,
          type: payload.file.type,
          location: response.response.location,
          parentMessageId: payload.parentMessageId,
          data: payload.data,
        },
      });
    } else {
      console.log('Media storage service failed to upload attachment');
    }
  } catch (e) {
    console.log(e);
  }
}

function* attachmentSender(
  dispatch: (arg0: { type: string; payload: { _id: any; file: any; channel: any; progress: number } }) => void
) {
  while (true) {
    const { payload } = yield take(CHAT_SEND_ATTACHMENT_PUBLIC);
    yield fork(awsMediaUploader, payload, dispatch);
  }
}

function* messageSenderTask(
  sendBirdAction: { sendUserMessage: any; sendFileMessage: any },
  connectedChannel: { url: any },
  dispatch: (arg0: {
    type: string;
    payload: { message: any; channelUrl: any };
    meta: { contact: { provider: any } };
  }) => void,
  connection: any
) {
  try {
    while (true) {
      const action = yield take(CHAT_SEND_MESSAGE_PUBLIC);
      if (action.payload.message.hasFile) {
        const attachment = {
          channel: connectedChannel,
          file: action.payload.message.file,
          nickName: action.payload.message.nickName,
          user: action.payload.message.user,
          parentMessageId: 0,
          data: action.payload.message.data,
        };
        let meta = yield select(state => state.auth.meta);
        meta = {
          ...meta,
          nickName: action.payload.message.nickName,
        };
        yield put({
          type: CHAT_SEND_ATTACHMENT_PUBLIC,
          payload: {
            ...attachment,
            _id: uuid4(),
            meta,
          },
        });
      } else {
        const originalMessage = action.payload.message;
        const requestPayload = {
          channel: connectedChannel,
          message: originalMessage.message.text,
          parentMessageId: 0,
          data:
            originalMessage?.data?.messageId === undefined
              ? {}
              : originalMessage?.data?.messageId !== 0
              ? originalMessage.data
              : {},
          handler(sentMessage, error) {
            if (sentMessage) {
              originalMessage._id = sentMessage.messageId;
              originalMessage.mentionedUsers = sentMessage.mentionedUsers;
              originalMessage.prevMsg = sentMessage.data !== '' ? [JSON.parse(sentMessage.data)] : [];
              originalMessage.createdAt = sentMessage?.createdAt;
              dispatch({
                type: CHAT_ADD_MESSAGE_PUBLIC,
                payload: {
                  message: originalMessage,
                  channelUrl: connectedChannel.url,
                },
                meta: {
                  contact: {
                    provider: connection,
                  },
                },
              });
            } else {
              console.log(error);
            }
          },
        };
        yield call(sendBirdAction.sendUserMessage, requestPayload);
      }
    }
  } catch (e) {
    console.log('Message Sender Task threw an error');
    console.log(e);
  }
}

function* dispatchMarkAsReadAction(channelUrl: any) {
  yield put({
    type: CHAT_MARK_AS_READ_PUBLIC,
    payload: {
      channelUrl,
    },
  });
}

function* markMessagesAsRead(channelUrl: string, sendBirdAction: SendBirdAction, sendBirdChannel: unknown) {
  let tempSendBirdAction = sendBirdAction;
  if (!sendBirdAction) {
    tempSendBirdAction = SendBirdAction.getInstance();
  }
  yield call(tempSendBirdAction.markAsRead, sendBirdChannel);
  yield call(dispatchMarkAsReadAction, channelUrl);
}

const mapMessageToGiftedChat = (message: {
  messageId: any;
  parentMessageId: any;
  message: any;
  createdAt: any;
  messageType: string;
  data: any;
  url: any;
  type: any;
  sender: { userId: any; nickname: any; profileUrl: any };
}) => {
  let giftedMessage = {} as IGiftedMessage;
  giftedMessage = {
    _id: message.messageId,
    parentMessageId: message.parentMessageId,
    prevMsg: message.data !== '' ? [JSON.parse(message.data)] : [],
    message: {
      text: message.message,
    },
    nickName: giftedMessage.system ? 'system' : message.sender.nickname,
    createdAt: message.createdAt,
    type: message.messageType,
    fileMeta:
      message.messageType === 'file'
        ? {
            url: message.url,
            type: message.type,
            loading: false,
            progress: 0,
          }
        : null,
    system: !!message.messageType && message.messageType === 'admin',
    user: null,
  };
  if (giftedMessage.system) {
    giftedMessage.user = {
      userId: message.messageId,
      name: 'System',
      avatar: null,
    };
  } else {
    giftedMessage.user = {
      userId: message.sender.userId,
      name: message.sender.nickname,
      avatar: message.sender.profileUrl,
    };
  }
  return giftedMessage;
};

function* readMarkerOnActiveChat(channelUrl, sendBirdAction, sendBirdChannel) {
  yield takeEvery(CHAT_MESSAGE_RECEIVED_PUBLIC, function* (action: IAction) {
    if (action.payload.channelUrl === channelUrl) {
      yield call(markMessagesAsRead, channelUrl, sendBirdAction, sendBirdChannel);
    }
  });
}

function* silentChatRefresher(
  action: { payload: { channelUrl: any; connection: any; currentUser: any } },
  dispatch: any
) {
  const { channelUrl, connection } = action.payload;
  const sendBirdAction = yield call(() => SendBirdAction.getInstance);
  const sendBirdChannel = yield call(getSBChannel, channelUrl, connection.connectionId);
  let chatMessages = yield call(sendBirdAction.getMessageList, sendBirdChannel, true);
  if (chatMessages) {
    chatMessages = chatMessages.map(mapMessageToGiftedChat);
    yield call(markMessagesAsRead, channelUrl, sendBirdAction, sendBirdChannel);
    yield put({
      type: CHAT_READY_PUBLIC,
      payload: {
        connectionStatus: connectionStatus.readyToChat,
        chatMessages: chatMessages,
        channelUrl,
      },
    });
  }
  yield fork(messageSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
  yield fork(mediaSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
  yield fork(attachmentSender, dispatch);
  yield fork(readMarkerOnActiveChat, channelUrl, sendBirdAction, sendBirdChannel);
}

export function* incomingMessageHandler() {
  const chatEvent = yield call(SendBirdChatEvent.getInstance);
  const chatEventChannel = yield call(createChatEventChannel, chatEvent);
  const { userId } = getUserInfo();
  try {
    while (true) {
      const { message, channelUrl, isDistinct, channel } = yield take(chatEventChannel);
      yield put({
        type: CHAT_MESSAGE_RECEIVED_PUBLIC,
        payload: {
          message: mapMessageToGiftedChat(message),
          channelUrl,
          isDistinct,
          senderId: message.sender.userId,
          isIncoming: message.sender.userId !== userId,
          sbMessage: message,
          sbChannel: channel,
        },
      });
    }
  } catch (e) {
    console.log('ChatEventChannel threw an error');
    console.log(e);
  }
}
/**
 * @Name liveChatFlowHandler
 * @param action , dispatch
 * @description This method is used to run chat flow & get all messages
 */
function* liveChatFlowHandler(
  action: { payload: { channelUrl: any; connection: any; currentUser: any } },
  dispatch: any
) {
  yield put({
    type: CHAT_INITIALIZING_PUBLIC,
    payload: {
      connectionStatus: connectionStatus.connecting,
    },
  });
  const { channelUrl, connection, currentUser } = action.payload;
  const sendBirdAction = yield call(SendBirdAction.getInstance);
  let sendBirdChannel = null;
  let newChannel = false;
  const channelName = `${currentUser.userId}-${connection.connectionId}`;
  try {
    const userId = {
      connectionId: connection.connectionId,
    };
    sendBirdChannel = yield call(getSBChannel, channelUrl, userId);
  } catch (error: any) {
    console.log(error);
    if (error?.code === 400201) {
      console.log('Channel not found So creating a new channel');
      sendBirdChannel = yield call(
        sendBirdAction.createGroupChannel,
        channelName,
        channelUrl,
        connection.userId,
        currentUser.userId
      );
      newChannel = true;
    } else {
      yield put({
        type: CHAT_INIT_FAILED_PUBLIC,
        payload: {
          message:
            error?.data?.errors[0]?.endUserMessage ||
            'Can not init chat. Please reload to connect chat again!',
        },
      });
    }
  }
  if (sendBirdChannel) {
    if (newChannel) {
      yield put({
        type: CHAT_READY_PUBLIC,
        payload: {
          connectionStatus: connectionStatus.readyToChat,
          chatMessages: [],
          channelUrl,
        },
      });
    } else {
      yield put({
        type: CHAT_INITIALIZING_PUBLIC,
        payload: {
          connectionStatus: connectionStatus.fetchingMessages,
        },
      });
      let chatMessages = yield call(sendBirdAction.getMessageList, sendBirdChannel, true);
      if (chatMessages) {
        chatMessages = chatMessages?.map(mapMessageToGiftedChat);
        yield call(markMessagesAsRead, channelUrl, sendBirdAction, sendBirdChannel);
        yield put({
          type: CHAT_READY_PUBLIC,
          payload: {
            connectionStatus: connectionStatus.readyToChat,
            chatMessages: chatMessages,
            channelUrl,
          },
        });
      }
    }
    yield fork(messageSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
    yield fork(mediaSenderTask, sendBirdAction, sendBirdChannel, dispatch, connection);
    yield fork(attachmentSender, dispatch);
    yield fork(readMarkerOnActiveChat, channelUrl, sendBirdAction, sendBirdChannel);
  }
}
/**
 * @Name connectSendBird
 * @param dispatch
 * @description This method is used to connect to sendbird
 */
function* connectSendBird() {
  const { userId, nickName } = getUserInfo();

  try {
    yield put({
      type: SENDBIRD_CONNECTING_PUBLIC,
    });
    const sendBird = yield call(SendBirdAction.getInstance);
    yield call(sendBird.connect, userId, nickName);
    yield fork(incomingMessageHandler);
    yield put({
      type: SENDBIRD_CONNECTED_PUBLIC,
    });
  } catch (e) {
    console.log(e);
    setTimeout(() => {
      callbackChannel.put({
        type: SENDBIRD_RECONNECTING_PUBLIC,
      });
      callbackChannel.put({
        type: SENDBIRD_RECONNECT_PUBLIC,
      });
    }, 1000);
  }
}

function* dispatchToStore(action) {
  yield put(action);
}
/**
 * @Name chatSaga
 * @param store
 * @description This method is used to initialize chat flow
 */
export default function* publicChatSaga(store: {
  dispatch: {
    (arg0: {
      type: string;
      payload: { message: any; channelUrl: any };
      meta: { contact: { provider: any } };
    }): void;
    (arg0: {
      type: string;
      payload: { _id: any; channelUrl: any; location: any };
      meta: { contact: { provider: any } };
    }): void;
  };
}) {
  let chatFlowHandle;
  while (true) {
    const action = yield take([CHAT_INITIALIZE_PUBLIC, CHAT_EXIT_PUBLIC]);
    const sendBird = yield call(SendBirdAction.getInstance);
    if (chatFlowHandle) {
      yield cancel(chatFlowHandle);
    }
    if (action.type === CHAT_INITIALIZE_PUBLIC) {
      if (!sendBird.sb.isSessionOpened) {
        yield take(SENDBIRD_CONNECTED_PUBLIC);
      }
      if (action.payload.channelUrl) {
        if (action.payload.connection.messages?.length > 0) {
          yield put({
            type: CHAT_READY_PUBLIC,
            payload: {
              connectionStatus: connectionStatus.readyToChat,
              chatMessages: action.payload.connection.messages,
              channelUrl: action.payload.connection.channelUrl,
            },
          });
          chatFlowHandle = yield fork(silentChatRefresher, action, store.dispatch);
        } else {
          chatFlowHandle = yield fork(liveChatFlowHandler, action, store.dispatch);
        }
      } else {
        yield put({
          type: CHAT_INITIALIZING_PUBLIC,
          payload: {
            connectionStatus: false,
            chatMessages: [],
          },
        });
        yield put({
          type: CHAT_READY_PUBLIC,
          payload: {
            connectionStatus: connectionStatus.failedToConnect,
            chatMessages: [],
            channelUrl: '',
          },
        });
      }
    }
  }
}

function* initializeSendbird() {
  yield fork(connectSendBird);
  const { userId } = getUserInfo();
  const sendbirdChat = yield call(SendbirdChat.init.bind(SendbirdChat), {
    appId: getSendbirdAppId(),
    localCacheEnabled: true,
    modules: [new GroupChannelModule()],
  });
  console.log('Sendbird chat connecting', sendbirdChat);
  yield call(sendbirdChat.connect.bind(sendbirdChat), userId);
  sbChat = sendbirdChat;
  const groupChannelFilter = new GroupChannelFilter();
  groupChannelFilter.includeEmpty = true;

  const collection = sbChat.groupChannel.createGroupChannelCollection({
    filter: groupChannelFilter,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
  });
  const channels = yield call(collection.loadMore.bind(collection));
  yield put({
    type: SENDBIRD_CHANNELS_FETCHED_PUBLIC,
    payload: {
      channels,
      initialFetch: true,
      collection,
    },
  });
  while (collection.hasMore) {
    const newChannels = yield call(collection.loadMore.bind(collection));
    yield put({
      type: SENDBIRD_CHANNELS_FETCHED_PUBLIC,
      payload: {
        channels: newChannels,
        collection,
      },
    });
  }
}

export function* publicChatConversationSaga(): IterableIterator<any> {
  yield takeLatest(USER_INITIAL_SUCCESSFUL, initializeSendbird);
  yield takeEvery(callbackChannel, dispatchToStore);
}
