import { BadgeAppointmentStatus } from 'components/shared/atoms/badge';

export enum AppointmentType {
  CURRENT = 'current',
  PENDING = 'pending',
  PAST = 'past',
}

export enum AppointmentStatus {
  NEEDS_ACTION = 'NEEDS_ACTION',
  FULFILLED = 'FULFILLED',
  CANCELLED = 'CANCELLED',
  BOOKED = 'BOOKED',
  NO_SHOW = 'NO_SHOW',
  PROPOSED = 'PROPOSED',
}

export const AppointmentStatusMap = {
  PENDING: BadgeAppointmentStatus.REQUESTED,
  PROPOSED: BadgeAppointmentStatus.REQUESTED,
  BOOKED: BadgeAppointmentStatus.SCHEDULED,
  CANCELLED: BadgeAppointmentStatus.CANCELED,
  FULFILLED: BadgeAppointmentStatus.COMPLETED,
  NO_SHOW: BadgeAppointmentStatus.NOSHOW,
};

export const appointmentTabOptions = [
  {
    label: 'All',
    tabKey: 'all',
  },
  {
    label: 'Scheduled',
    tabKey: 'current',
  },
  {
    label: 'Requested',
    tabKey: 'pending',
  },
  {
    label: 'Past',
    tabKey: 'past',
  },
];

export const allStatusOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Requested',
    value: 'requested',
  },
  {
    label: 'Scheduled',
    value: AppointmentStatus.BOOKED,
  },
  {
    label: 'Completed',
    value: AppointmentStatus.FULFILLED,
  },
  {
    label: 'Canceled',
    value: AppointmentStatus.CANCELLED,
  },
  {
    label: 'No show',
    value: AppointmentStatus.NO_SHOW,
  },
];

export const scheduledStatusOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Scheduled',
    value: AppointmentStatus.BOOKED,
  },
];

export const requestedStatusOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Requested',
    value: 'requested',
  },
];

export const pastStatusOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Completed',
    value: AppointmentStatus.FULFILLED,
  },
  {
    label: 'Canceled',
    value: AppointmentStatus.CANCELLED,
  },
  {
    label: 'No show',
    value: AppointmentStatus.NO_SHOW,
  },
];

export const sortOptions = [
  {
    label: 'Recent first',
    value: 'startTime',
  },
];

export const pageHeaderTitleMap = {
  all: 'All appointments',
  current: 'Scheduled appointments',
  pending: 'Requested appointments',
  past: 'Past appointments',
};

export const appointmentFilterStatusOptions = [
  { label: 'Scheduled', value: AppointmentStatus.BOOKED, filterBy: 'status' },
  { label: 'Waiting for Member', value: AppointmentStatus.PROPOSED, filterBy: 'status' },
  { label: 'Waiting for Provider', value: AppointmentStatus.NEEDS_ACTION, filterBy: 'status' },
  { label: 'No Show', value: AppointmentStatus.NO_SHOW, filterBy: 'status' },
  { label: 'Cancelled', value: AppointmentStatus.CANCELLED, filterBy: 'status' },
  { label: 'Completed', value: AppointmentStatus.FULFILLED, filterBy: 'status' },
];

export const RECURRING_SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PAUSED: 'PAUSED',
  CANCELLED: 'CANCELLED',
};
