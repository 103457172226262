import { IAction } from 'redux/store/types';
import {
  CHAT_ADD_MESSAGE,
  CHAT_EXIT,
  CHAT_INITIALIZING,
  CHAT_INIT_FAILED,
  CHAT_MARK_AS_READ,
  CHAT_MEDIA_SENT,
  CHAT_MEDIA_UPLOAD_PROGRESS,
  CHAT_MESSAGE_RECEIVED,
  CHAT_READY,
  CHAT_SEND_ATTACHMENT,
  SENDBIRD_CHANNELS_FETCHED,
  SENDBIRD_CONNECTED,
  SENDBIRD_CONNECTING,
  SENDBIRD_CONNECT_FAILED,
  SENDBIRD_RECONNECTING,
} from './actions';
import { ChatState, IGiftedMessage } from './types';
import { USER_LOGOUT } from '../auth/actions';

export const DEFAULT = {
  isLoading: true,
  errorMsg: '',
  connectionStatus: 0,
  messages: [] as any[],
  sendingMessage: false,
  loadEarlier: true,
  isLoadingEarlier: false,
  channelUrl: null,
  sendbirdStatus: 0,
  channels: [] as any[],
  channelMembers: [] as any[],
};

export const getArtificialLoadingMediaMessage = (
  message: { _id: any; file: { uri: any; type: any }; messageId: any },
  meta: { userId: any; nickName: any }
) => {
  let giftedMessage = {} as IGiftedMessage;
  giftedMessage = {
    _id: message._id,
    message: {
      text: null,
    },
    nickName: giftedMessage.system ? 'system' : meta.nickName,
    createdAt: new Date().getTime(),
    type: 'file',
    fileMeta: {
      url: message.file.uri,
      type: message.file.type,
      loading: true,
      progress: 0,
    },
    system: false,
    user: null,
  };
  if (giftedMessage.system) {
    giftedMessage.user = {
      userId: message.messageId,
      name: 'System',
      avatar: null,
    };
  } else {
    giftedMessage.user = {
      userId: meta.userId,
      name: meta.nickName,
      avatar: null,
    };
  }
  return giftedMessage;
};

export default function chatReducer(state = DEFAULT, action: IAction): ChatState {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGOUT: {
      return {
        ...DEFAULT,
      };
    }
    case CHAT_INIT_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload.message,
      };
    }

    case SENDBIRD_CHANNELS_FETCHED: {
      return {
        ...state,
        channels: payload.initialFetch ? payload.channels : [...state.channels, ...payload.channels],
      };
    }

    case SENDBIRD_CONNECTED: {
      return {
        ...state,
        sendbirdStatus: 2,
      };
    }
    case SENDBIRD_CONNECT_FAILED: {
      return {
        ...state,
        sendbirdStatus: 0,
      };
    }
    case SENDBIRD_RECONNECTING: {
      return {
        ...state,
        sendbirdStatus: 3,
      };
    }
    case SENDBIRD_CONNECTING: {
      return {
        ...state,
        sendbirdStatus: 1,
      };
    }
    case CHAT_INITIALIZING: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
        connectionStatus: payload.connectionStatus,
        messages: [],
      };
    }
    case CHAT_READY: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        connectionStatus: payload.connectionStatus,
        messages: payload.chatMessages || [],
        channelUrl: payload.channelUrl,
        channelMembers: payload.channelMembers || [],
      };
    }
    case CHAT_MARK_AS_READ: {
      const { channelUrl } = payload;
      return {
        ...state,
        channels: state.channels.map((channel: any) => {
          if (channel.url === channelUrl) {
            channel.unreadMessageCount = 0;
          }
          return channel;
        }),
      };
    }
    case CHAT_ADD_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, payload.message],
      };
    }
    case CHAT_MESSAGE_RECEIVED: {
      const res = state.messages.map(message => {
        if (message._id === payload.message.parentMessageId) {
          return { ...message, thread: [...message.thread, payload.message] };
        }
        if (message._id === 0) {
          return { ...message, thread: [] };
        }
        return message;
      });
      const { channelUrl, sbChannel } = payload;
      return {
        ...state,
        messages:
          state.channelUrl && state.channelUrl === channelUrl && payload.message.parentMessageId === 0
            ? [{ ...payload.message, thread: [] }, ...state.messages]
            : state.channelUrl && state.channelUrl === channelUrl && payload.message.parentMessageId !== 0
            ? res
            : state.messages,
        channels: state.channels.map((channel: any) => {
          if (channel.url === sbChannel.url) {
            return sbChannel;
          }
          return channel;
        }),
      };
    }
    case CHAT_MEDIA_SENT: {
      let { messages } = state;
      let updated = false;
      messages = messages.map(message => {
        if (message._id === payload._id) {
          message._id = payload.messageId;
          message.prevMsg =
            payload?.prevMsg?.messageId === undefined
              ? []
              : payload?.prevMsg?.messageId !== 0
              ? [payload.prevMsg]
              : [];
          message.fileMeta = {
            loading: false,
            url: payload.location,
            type: payload.type,
          };
          message.user = payload.user;
          message.createdAt = payload.msgCreatedAt;
          updated = true;
        }
        return message;
      });
      if (!updated) {
        /* console.log(action);
        const message = getArtificialLoadingMediaMessage(payload, action.meta);
        console.log(message);
        (message as any).fileMeta.loading = false;
        (message as any).fileMeta.url = payload.location; */
        const message = {
          createdAt: payload.msgCreatedAt,
          prevMsg:
            payload?.prevMsg?.messageId === undefined
              ? []
              : payload?.prevMsg?.messageId !== 0
              ? [payload.prevMsg]
              : [],
          fileMeta: {
            loading: false,
            type: payload.message.type,
            url: payload.message.plainUrl,
          },
          message: { text: undefined },
          parentMessageId: 0,
          system: false,
          thread: [],
          type: 'file',
          _id: payload.messageId,
          user: payload.user,
        };
        messages = [message as never, ...messages];
      }
      return {
        ...state,
        messages,
        channels: state.channels.map((channel: any) => {
          if (channel.url === payload.channelUrl) {
            channel.lastMessage = payload.message;
          }
          return channel;
        }),
      };
    }

    case CHAT_MEDIA_UPLOAD_PROGRESS: {
      let { messages } = state;
      let updated = false;
      messages = messages.map(message => {
        if (message.id === payload.id) {
          message.fileMeta.progress = payload.progress;
          updated = true;
        }
        return message;
      });
      if (!updated) {
        const message = getArtificialLoadingMediaMessage(payload, action.meta);
        (message as any).fileMeta.progress = payload.progress;
        messages = [message as never, ...messages];
      }
      return {
        ...state,
        messages,
      };
    }

    case CHAT_SEND_ATTACHMENT: {
      const res = state.messages.map(message => {
        if (message._id === action.payload.parentMessageId) {
          return {
            ...message,
            thread: [
              ...message.thread,
              getArtificialLoadingMediaMessage(action.payload, action.payload.meta),
            ],
          };
        }
        return message;
      });
      const giftedMessage = getArtificialLoadingMediaMessage(payload, payload.meta);
      return {
        ...state,
        messages:
          action.payload.parentMessageId === 0 ? [{ ...giftedMessage, thread: [] }, ...state.messages] : res,
      };
    }

    case CHAT_EXIT:
    default: {
      return state;
    }
  }
}
