import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';

// Returns active and past connections for current logged in user
export const getConnections = (userId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CONNECTIONS, undefined, undefined, { userId });
};

// Retrieves a list of care team members for the provided member specified by userId
export const getCareTeamMembers = (userId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CARE_TEAM_MEMBERS, undefined, { userId });
};
