import { call, cancel, cancelled, delay, fork, put, take, select } from 'redux-saga/effects';

import { ConnectionStatus } from 'botframework-directlinejs';

import dayjs from 'utils/dayjs';
import getConfig from 'config';
import { DirectLineClient } from 'lib/directline-client';
import { ContentfulClient } from 'lib/contentful-client';

import * as ProxyService from 'services/proxy/proxy.service';

import {
  CHAT_ADD_MESSAGE_PUBLIC,
  CHAT_EXIT_PUBLIC,
  CHAT_FETCH_NEXT_MSG_PUBLIC,
  CHAT_HIDE_SINGLE_CHOICES_PUBLIC,
  CHAT_INIT_FAILED_PUBLIC,
  CHAT_INIT_SUCCESSFUL_PUBLIC,
  CHAT_INITIALIZING_PUBLIC,
  CHAT_LOAD_EARLIER_PUBLIC,
  CHAT_LOADED_EARLIER_PUBLIC,
  CHAT_LOADING_EARLIER_PUBLIC,
  CHAT_PAUSE_PUBLIC,
  CHAT_REQUEST_INITIALIZE_PUBLIC,
  CHAT_RESPONSE_RECEIVED_PUBLIC,
  CHAT_SEND_MESSAGE_PUBLIC,
  CHAT_SEND_MESSAGE_FAILED_PUBLIC,
  CHAT_SEND_MESSAGE_IN_PROGRESS_PUBLIC,
  CHAT_SEND_MESSAGE_SUCCESSFUL_PUBLIC,
  CHAT_TOKEN_EXPIRED_PUBLIC,
  CHAT_TYPING_DETECTED_PUBLIC,
  CHAT_UPDATE_CONNECTION_STATUS_PUBLIC,
} from './actions';
import { NETWORK_STATUS_CHANGED } from '../auth/actions';
import { FETCH_BOT_PROGRESS_PUBLIC } from '../public-connection/actions';

import { v4 as uuidv4 } from 'uuid';

let pendingSendActions = [] as any[];

const botMessageToGiftedMessage = botMessage => {
  const contentType =
    botMessage.type &&
    botMessage.attachments &&
    botMessage.attachments.length &&
    botMessage.attachments[0].contentType;

  if (
    (contentType === 'message' ||
      contentType === 'provider-message' ||
      contentType === 'text-input' ||
      // contentType === "education" ||
      contentType === 'single-message') &&
    !botMessage.text
  ) {
    botMessage.type = contentType;
    botMessage.text =
      botMessage.attachments && botMessage.attachments.length && botMessage.attachments[0].content.label;
  }
  if (contentType === 'text-input') {
    botMessage.attachments[0].dataType = botMessage.attachments[0].content.dataType;
  }
  if (contentType === 'education') {
    botMessage.type = contentType;
  }

  if (contentType === 'single-select' || contentType === 'multi-select') {
    botMessage.type = contentType;
    botMessage.quickReplies = {
      type: contentType === 'single-select' ? 'radio' : 'checkbox',
      keepIt: false,
      values: [],
    };

    for (const choice of botMessage.attachments[0].content.choices) {
      const value = choice.value || choice;
      botMessage.quickReplies.values.push({ title: value, value: value });
    }
  }
  if (contentType === 'rating-scale') {
    botMessage.type = contentType;
    const ratingBlock = botMessage.attachments[0].content;
    botMessage.ratingScale = ratingBlock.ratingScale;
  }

  botMessage._id = botMessage.id;
  botMessage.createdAt = botMessage.timestamp;
  botMessage.timestamp = new Date(botMessage.timestamp).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  botMessage.user = {
    _id: botMessage.from.id,
    name: botMessage.from.name,
    avatar: 'https://0.gravatar.com/avatar/c124d114e21b520e7d301505cca4b95e?s=32&d=identicon&r=G',
  };
  return botMessage;
};

const activityHandler = function* (action) {
  try {
    while (true) {
      const { payload } = yield take(CHAT_RESPONSE_RECEIVED_PUBLIC);
      const activity = botMessageToGiftedMessage(payload);
      const contentType =
        activity.type &&
        activity.attachments &&
        activity.attachments.length &&
        activity.attachments[0].contentType;

      // Set Avatar
      activity.user.avatar = action.meta.contact.profilePicture;

      if (activity.type === 'typing') {
        yield put({
          type: CHAT_TYPING_DETECTED_PUBLIC,
          payload: activity,
          meta: action.meta,
        });
      } else {
        if (contentType === 'education') {
          activity.attachments = yield call(
            getContentfulData,
            activity.attachments[0].content.educationContentSlug
          );
        }
        const lastMessage = yield select(state => state.chatbot.chatMessages[0]);
        if (lastMessage) {
          if (lastMessage._id !== activity._id) {
            yield fork(function* () {
              yield delay(1000);
              yield put({
                type: CHAT_ADD_MESSAGE_PUBLIC,
                payload: activity,
                meta: action.meta,
              });
            });
          }
        } else {
          yield fork(function* () {
            yield delay(1000);
            yield put({
              type: CHAT_ADD_MESSAGE_PUBLIC,
              payload: activity,
              meta: action.meta,
            });
          });
        }
      }
    }
  } catch (error) {
    console.log('Activity Handler threw an error');
    console.log(error);
  } finally {
    console.log('Activity Handler terminated...');
  }
};

async function getContentfulData(slug) {
  const educationalContent = await ContentfulClient.getEntries({
    content_type: 'educationalContent',
    'sys.id': slug,
  });
  return [
    {
      contentType: 'education',
      content: {
        educationContentSlug: slug,
        contentfulData:
          educationalContent.total === 1 && educationalContent.items.length > 0
            ? educationalContent.items[0].fields
            : {},
      },
    },
  ];
}

function createMessage(payload) {
  let answer = payload.text;
  if (payload.type === 'event') {
    answer = null;
  }
  if (answer && answer.charAt(0) === '[') {
    answer = JSON.parse(answer);
  }
  if (answer && !(answer instanceof Array)) {
    answer = [answer];
  }
  if (answer && answer.length && answer.length === 0) {
    answer = null;
  }
  const patientId = payload.from.id.split(',')[0];
  const contactId = payload.from.id.split(',')[1];
  return {
    patientId,
    contactId,
    answers: answer,
  };
}

function prepareResponse(contentBlock) {
  if (contentBlock.type === 'provider-message' || contentBlock.type === 'text-input') {
    return {
      attachments: [
        {
          content: {
            delay: 1,
            label: contentBlock.message,
            dataType: contentBlock.dataType,
          },
          contentType: contentBlock.type,
        },
      ],
    };
  } else if (
    contentBlock.type === 'single-select' ||
    contentBlock.type === 'multi-dropdown' ||
    contentBlock.type === 'rating-scale' ||
    contentBlock.type === 'education' ||
    contentBlock.type === 'provider-prompt' ||
    contentBlock.type === 'activity' ||
    contentBlock.type === 'filtered-providers' ||
    contentBlock.type === 'telehealth-services' ||
    contentBlock.type === 'payment'
  ) {
    return {
      text: contentBlock.message,
      attachments: [
        {
          content: contentBlock,
          contentType: contentBlock.type,
        },
      ],
    };
  } else if (contentBlock.type === 'multi-select') {
    return {
      text: contentBlock.message,
      attachments: [
        {
          content: contentBlock,
          contentType: contentBlock.type,
        },
      ],
    };
  }
}

function sendMessage(payload, dispatch) {
  const { memberId, ...prepayload } = payload;
  return ProxyService.bypassProxy({
    method: 'POST',
    url: `${getConfig.api.baseUrl}/conversation/processUserResponse`,
    memberId,
    body: createMessage(prepayload),
  }).then(({ data: response }) => {
    if (response.errors) {
      response.conversationEnded = true;
    }
    let message: any = null;
    if (response.conversationEnded) {
      message = {
        attachments: [
          {
            content: {
              delay: 1,
              label:
                "That's it for this chat!\n" +
                'Please note this is not a dynamic chat and your message will not be received by a real-life human. To connect with someone, please message your matchmaker.\n' +
                'Confidant is not an emergency service. If you’re experiencing an emergency situation you should call 911 or the National Suicide Prevention Help Line at 1-800-273-8255.',
            },
            contentType: 'single-message',
          },
        ],
      };
    } else {
      message = prepareResponse(response);
    }
    dispatch({
      type: CHAT_RESPONSE_RECEIVED_PUBLIC,
      payload: {
        ...message,
        type: 'message',
        id: uuidv4(),
        timestamp: new Date().getTime(),
        from: {
          id: uuidv4(),
          name: 'Chatbot',
        },
      },
    });
  });
}

const sendMessageHandler = function* (action, delayAmount, dispatch) {
  yield put({
    type: CHAT_SEND_MESSAGE_IN_PROGRESS_PUBLIC,
    payload: action.payload,
    meta: action.meta,
  });
  yield put({ type: CHAT_HIDE_SINGLE_CHOICES_PUBLIC });

  try {
    // We need to put some delay if message is coming from Auto Reply worker
    if (delayAmount !== 0) {
      console.log('Yielding delay of ' + delayAmount);
      yield delay(delayAmount); // Removed Delay Time on QA
    }
    const response = yield call(sendMessage, { ...action.payload, memberId: action.meta.memberId }, dispatch);
    yield put({
      type: CHAT_SEND_MESSAGE_SUCCESSFUL_PUBLIC,
      payload: { ...action.payload, response: response },
      meta: action.meta,
    });
  } catch (error) {
    console.warn(error);
    yield put({
      type: CHAT_SEND_MESSAGE_FAILED_PUBLIC,
      payload: { ...action.payload, response: error },
      meta: action.meta,
    });
  } finally {
    console.log('exiting sender message handler...');
  }
};

const loadEarlierHandler = function* () {
  while (true) {
    const { payload, meta } = yield take(CHAT_LOAD_EARLIER_PUBLIC);

    yield put({ type: CHAT_LOADING_EARLIER_PUBLIC, payload: {}, meta: meta });

    try {
      const { data } = yield call(ProxyService.bypassProxy, {
        method: 'GET',
        url: `${getConfig.api.baseUrl}/conversation/chat/history`,
        memberId: meta.memberId,
        params: {
          contactId: meta.contact.connectionId,
          skip: payload.watermark,
        },
      });

      if (!data.errors) {
        const { data: conversationHistory } = yield call(processConversationHistory, data, meta.contact);
        yield put({
          type: CHAT_LOADED_EARLIER_PUBLIC,
          payload: { conversationHistory: conversationHistory },
          meta: meta,
        });
      }
    } catch (e) {
      yield put({ type: CHAT_LOADED_EARLIER_PUBLIC, payload: { conversationHistory: [] }, meta: meta });
      console.log(e);
    }
  }
};

async function processConversationHistory(conversationHistory, contact, isFirstCall = false) {
  for (const message of conversationHistory) {
    message._id = uuidv4();
    message.createdAt = message.timestamp;
    message.timestamp = dayjs(message.timestamp).format('YYYY-MM-DD');
    message.user = {
      _id:
        message.from === contact.connectionId
          ? contact.connectionId
          : message.from + ',' + contact.connectionId,
      name: message.from === contact.connectionId ? contact.name : 'User',
      avatar: message.from === contact.connectionId ? contact.profilePicture : '',
    };

    if (message.educationSlug) {
      message.type = 'education';
      message.attachments = await getContentfulData(message.educationSlug);
    }
    if (message.type === 'provider-prompt') {
      message.text = 'Here is the list of providers';
      message.attachments = [
        {
          contentType: 'provider-prompt',
          content: {},
        },
      ];
    }
    if (message.type === 'telehealth-services') {
      message.text = 'Learn more about our clinical services';
      message.attachments = [
        {
          contentType: 'telehealth-services',
          content: {},
        },
      ];
    }
    if (message.type === 'filtered-providers') {
      message.text = 'Our coaches are available to meet with you';
      message.attachments = [
        {
          contentType: 'filtered-providers',
          content: { popupText: message.popupText },
        },
      ];
    }
    if (isFirstCall) {
      if (message.type === 'single-select') {
        if (message.choices && message.choices.length > 0) {
          message.attachments = [
            {
              contentType: message.type,
              content: {
                choices: message.choices,
              },
            },
          ];
        }
      }
      if (message.type === 'multi-select') {
        if (message.choices && message.choices.length > 0) {
          const choices = message.choices;
          message.attachments = [
            {
              contentType: message.type,
              content: {
                choices,
              },
            },
          ];
        }
      }
      if (message.type === 'rating-scale') {
        message.attachments = [
          {
            contentType: message.type,
          },
        ];
      }
      if (message.type === 'text-input') {
        console.log('Found');
        message.attachments = [
          {
            contentType: message.type,
            dataType: message.dataType,
          },
        ];
      }
      if (message.type === 'activity') {
        message.attachments = [
          {
            contentType: message.type,
            content: {
              choices: message.choices,
              activity: message.activity,
            },
          },
        ];
      }
      if (message.type === 'payment') {
        message.attachments = [
          {
            content: {
              type: 'payment',
            },
            contentType: message.type,
          },
        ];
      }
    }
  }
  if (conversationHistory && conversationHistory.length > 0 && isFirstCall && !contact.archived) {
    if (
      conversationHistory[0].type === 'provider-message' ||
      conversationHistory[0].type === 'provider-prompt' ||
      conversationHistory[0].type === 'filtered-providers' ||
      conversationHistory[0].type === 'telehealth-services'
    ) {
      const singleSelect = {} as any;
      singleSelect._id = uuidv4();
      singleSelect.user = {
        _id: contact.connectionId,
        name: contact.name,
        avatar: contact.profilePicture,
      };
      singleSelect.text =
        'We noticed you left the chat in the middle of a conversation. Let’s pickup where we left off.';
      singleSelect.type = 'single-select';
      singleSelect.attachments = [
        {
          contentType: 'single-select',
          content: {
            choices: ['Get Started'],
          },
        },
      ];
      conversationHistory = [singleSelect, ...conversationHistory];
    }
  }

  return conversationHistory;
}

/**
 * Does the following
 * 1. Initialize chat flow, get conversation context, directline token etc
 * 2. Subscribe to connection status updates
 * 3. Subscribe to incoming activity stream & register auto reply for provider-message content block
 * 4. Attach sendMessage Handler
 * 5. Handle CHAT_EXIT_PUBLIC actions to clean up chat and destroy everything
 */
export function* chatFlowHandler(action, dispatch) {
  let { pendingAutoReply } = action.payload;
  const { watermark } = action.payload;
  const publicCBString = localStorage.getItem('chatbot');

  yield put({ type: CHAT_INITIALIZING_PUBLIC, payload: {}, meta: action.meta });

  try {
    const { data: conversationContext } = yield call(ProxyService.bypassProxy, {
      method: 'POST',
      url: `${getConfig.api.baseUrl}/conversation/token/generate`,
      memberId: action.meta.memberId,
      body: {
        contactId: action.meta.contact.connectionId,
      },
    });

    if (conversationContext.errors) {
      throw new Error('Error retreiving conversation context: ' + JSON.stringify(conversationContext));
    }

    const { data } = yield call(ProxyService.bypassProxy, {
      method: 'GET',
      url: `${getConfig.api.baseUrl}/conversation/chat/history`,
      memberId: action.meta.memberId,
      params: {
        contactId: action.meta.contact.connectionId,
        skip: watermark,
      },
    });
    const conversationHistory = yield call(processConversationHistory, data, action.meta.contact, true);
    yield fork(activityHandler, action);
    yield fork(getNextMessage, conversationContext, dispatch);
    yield fork(directLineTokenRefreshHandler, { ...conversationContext, memberId: action.meta.memberId });
    yield fork(loadEarlierHandler);

    yield put({
      type: CHAT_INIT_SUCCESSFUL_PUBLIC,
      payload: {
        conversationContext: conversationContext,
        connectionStatus: ConnectionStatus.Uninitialized,
        contact: action.payload.contact,
        watermark: conversationHistory.length,
        isConnectedBefore: true,
        conversationHistory: conversationHistory,
      },
      meta: action.meta,
    });

    const activeConnections = yield select(state => state.connection.activeConnections);
    const currentConnection = activeConnections.find(
      connection => connection.connectionId === action.meta.contact.connectionId
    );
    if (
      conversationHistory.length === 0 ||
      (currentConnection && currentConnection.progress && currentConnection.progress.percentage === 0)
    ) {
      console.log('1st message auto triggering');
      pendingAutoReply = true;
    }
    if (pendingAutoReply) {
      yield call(
        sendMessageHandler,
        {
          type: CHAT_SEND_MESSAGE_PUBLIC,
          payload: {
            from: {
              id: conversationContext.patientId + ',' + conversationContext.contactId,
              name: action.meta.contact.name,
            },
            type: 'event',
            text: 'Ping',
          },
          meta: action.meta,
        },
        0,
        dispatch
      );
    }

    while (true) {
      const action = yield take(CHAT_SEND_MESSAGE_PUBLIC);
      // TODO: const networkConnected = yield select(state => state.auth.networkConnected);
      const isAuthenticated = yield select(state => state.auth.isAuthenticated);
      if ((!isAuthenticated && publicCBString === 'public-chatbot') || isAuthenticated) {
        yield fork(sendMessageHandler, action, 0, dispatch);
      } else {
        pendingSendActions.push(action);
      }
    }
  } catch (error) {
    yield put({ type: CHAT_INIT_FAILED_PUBLIC, payload: error, meta: action.meta });
  } finally {
    if (yield cancelled()) {
      console.log('ChatFlow closing...');
    }
  }
}

function* getNextMessage(conversationContext, dispatch) {
  const publicCBString = localStorage.getItem('chatbot');
  while (true) {
    const { payload, meta } = yield take(CHAT_FETCH_NEXT_MSG_PUBLIC);
    const action = {
      type: CHAT_SEND_MESSAGE_PUBLIC,
      payload: {
        from: {
          id: conversationContext.patientId + ',' + conversationContext.contactId,
          name: 'User', // TODO:: repalce when sender user name
        },
        type: 'event',
        text: payload._id,
        userData: 'event',
      },
      meta: meta,
    };
    // TODO: const networkConnected = yield select(state => state.auth.networkConnected);
    const isAuthenticated = yield select(state => state.auth.isAuthenticated);
    if ((!isAuthenticated && publicCBString === 'public-chatbot') || isAuthenticated) {
      yield fork(sendMessageHandler, action, 0, dispatch);
    } else {
      console.log('Going to pending');
      pendingSendActions.push(action);
    }
  }
}

function* directLineTokenRefreshHandler(conversationContext) {
  const { memberId, ...conversationContexts } = conversationContext;
  while (true) {
    yield take(CHAT_TOKEN_EXPIRED_PUBLIC);

    const { data } = yield call(ProxyService.bypassProxy, {
      method: 'POST',
      url: `${getConfig.api.baseUrl}/profile/roaster/token/refresh`,
      memberId,
      body: {},
    });

    if (data.errors) {
      console.log('Error refreshing token', data.errors);
    } else {
      console.log('Directline token refreshed');
      DirectLineClient.createInstance(conversationContexts.token);

      yield put({ type: CHAT_UPDATE_CONNECTION_STATUS_PUBLIC, payload: { status: ConnectionStatus.Online } });
    }
  }
}

function* chatPausedHandler() {
  while (true) {
    yield take(CHAT_PAUSE_PUBLIC);
  }
}

function* botNetworkReInitializer(dispatch) {
  const publicCBString = localStorage.getItem('chatbot');
  while (true) {
    yield take(NETWORK_STATUS_CHANGED);
    // TODO: const networkConnected = yield select(state => state.auth.networkConnected);
    const isAuthenticated = yield select(state => state.auth.isAuthenticated);
    if ((!isAuthenticated && publicCBString === 'public-chatbot') || isAuthenticated) {
      yield put({ type: CHAT_TOKEN_EXPIRED_PUBLIC });

      if (pendingSendActions.length > 0) {
        for (const action of pendingSendActions) {
          yield fork(sendMessageHandler, action, 0, dispatch);
        }
        pendingSendActions = [];
      }
    }
  }
}

export default function* publicChatbotSaga(store) {
  while (true) {
    const action = yield take(CHAT_REQUEST_INITIALIZE_PUBLIC);
    console.log('initial chat flow starting ...................');
    const chatFlowHandle = yield fork(chatFlowHandler, action, store.dispatch);
    const chatPauseHandle = yield fork(chatPausedHandler);
    const networkHandler = yield fork(botNetworkReInitializer, store.dispatch);
    const nextAction = yield take([CHAT_EXIT_PUBLIC, CHAT_INIT_FAILED_PUBLIC]);

    if (nextAction.type === CHAT_EXIT_PUBLIC) {
      try {
        yield cancel(chatFlowHandle);
        yield cancel(chatPauseHandle);
        yield cancel(networkHandler);
        yield put({ type: FETCH_BOT_PROGRESS_PUBLIC, payload: action.meta.contact.connectionId });
      } catch (error) {
        console.log(error);
      }
    }
  }
}
