import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as AppointmentService from 'services/appointment/appointment.service';
import {
  ACCEPT_APPOINTMENT,
  ACCEPT_APPOINTMENT_FAILED,
  REJECT_APPOINTMENT,
  REJECT_APPOINTMENT_FAILED,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_REQUEST_FAILED,
  CREATE_APPOINTMENT_REQUEST_SUCCESSFUL,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILED,
  FETCH_APPOINTMENTS_SUCCESSFUL,
  REJECT_APPOINTMENT_SUCCESSFUL,
  ACCEPT_APPOINTMENT_SUCCESSFUL,
  FETCH_ALL_PROVIDER_SERVICES,
  FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL,
  FETCH_MASTER_SCHEDULE,
  FETCH_MASTER_SCHEDULE_SUCCESSFUL,
  FETCH_INSURANCES_BY_STATE,
  FETCH_INSURANCES_BY_STATE_SUCCESSFUL,
  FETCH_INSURANCES_BY_STATE_FAILED,
  FETCH_ALL_PROVIDER_SERVICES_FAILED,
} from './actions';

import { showSnackbar } from 'redux/modules/snackbar';
import { AppointmentAction } from './types';

function* fetchAppointments(action: IAction) {
  try {
    const response = yield call(AppointmentService.getAppointments, action.payload.queryParams);
    if (response.status === 200) {
      const appointments = response?.data?.results;
      yield put({
        type: FETCH_APPOINTMENTS_SUCCESSFUL,
        payload: {
          appointments,
          currentPage: action.payload?.params?.pageNumber,
          totalPages: Math.ceil(response?.data?.total / response?.data?.size),
          totalRecords: response?.data?.total,
        },
      });
    } else {
      yield put({
        type: FETCH_APPOINTMENTS_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_APPOINTMENTS_FAILED,
      payload: { message: e },
    });
  }
}

function* createAppointment(action: IAction) {
  try {
    yield call(AppointmentService.requestNewAppointment, action.payload);
    yield put({ type: CREATE_APPOINTMENT_REQUEST_SUCCESSFUL });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Your appointment has created',
      })
    );
  } catch (e: any) {
    let message = '';
    if (e?.status === 500) {
      message = e?.data.error || 'Something went wrong';
    } else {
      message = e?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    }

    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      })
    );
    yield put({
      type: CREATE_APPOINTMENT_REQUEST_FAILED,
      payload: { message },
    });
  }
}

function* acceptAppointment(action: IAction) {
  const { appointmentId, requestBody } = action.payload;
  try {
    yield call(AppointmentService.acceptAppointment, requestBody, { appointmentId });
    yield put({
      type: ACCEPT_APPOINTMENT_SUCCESSFUL,
      payload: { appointmentId },
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Appointment request accepted',
      })
    );
  } catch (e: any) {
    let message = '';
    if (e?.status === 500) {
      message = e?.data.error || 'Something went wrong';
    } else {
      message = e?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    }

    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      })
    );
    yield put({
      type: ACCEPT_APPOINTMENT_FAILED,
      payload: { message },
    });
  }
}

function* rejectAppointment(action: AppointmentAction) {
  const { appointmentId, requestBody, onSuccess } = action.payload;
  try {
    yield call(AppointmentService.rejectAppointment, requestBody, { appointmentId });
    yield put({
      type: REJECT_APPOINTMENT_SUCCESSFUL,
      payload: { appointmentId },
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Appointment cancelled',
      })
    );
    if (onSuccess) {
      onSuccess();
    }
  } catch (e: any) {
    let message = '';
    if (e?.status === 500) {
      message = e?.data.error || 'Something went wrong';
    } else {
      message = e?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    }

    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      })
    );
    yield put({
      type: REJECT_APPOINTMENT_FAILED,
      payload: { message },
    });
  }
}

function* fetchAllProviderServices(action) {
  try {
    const { initial, providerId, memberId, state } = action.payload;
    const params: any = {
      initial: !!initial,
    };
    if (state) {
      params.state = state;
    }
    if (providerId && providerId.trim() !== '') {
      params.providerId = providerId;
      if (memberId && memberId.trim() !== '') {
        params.memberId = memberId;
      }
    }
    const { data } = yield call(AppointmentService.getAllProviderServices, params);
    yield put({
      type: FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL,
      payload: {
        schedule: data,
      },
    });
  } catch (e: any) {
    console.warn(e);
    yield put({
      type: FETCH_ALL_PROVIDER_SERVICES_FAILED,
      payload: {
        error: e?.message,
      },
    });
  }
}

function* fetchMasterSchedule(action) {
  try {
    console.log(action.payload);
    const { data } = yield call(AppointmentService.getAvailableSlots, action?.payload);
    yield put({
      type: FETCH_MASTER_SCHEDULE_SUCCESSFUL,
      payload: {
        data: data?.results,
      },
    });
  } catch (e) {
    console.warn(e);
  }
}

function* fetchInsurance(action: IAction) {
  try {
    const { stateName } = action.payload;
    const response = yield call(AppointmentService.fetchInsurance, stateName);
    yield put({
      type: FETCH_INSURANCES_BY_STATE_SUCCESSFUL,
      payload: response.data,
    });
  } catch (e) {
    yield put({
      type: FETCH_INSURANCES_BY_STATE_FAILED,
      payload: { message: e },
    });
  }
}
export default function* appointmentSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_APPOINTMENTS, fetchAppointments);
  yield takeLatest(CREATE_APPOINTMENT_REQUEST, createAppointment);
  yield takeLatest(ACCEPT_APPOINTMENT, acceptAppointment);
  yield takeLatest(REJECT_APPOINTMENT, rejectAppointment);
  yield takeLatest(FETCH_ALL_PROVIDER_SERVICES, fetchAllProviderServices);
  yield takeLatest(FETCH_MASTER_SCHEDULE, fetchMasterSchedule);
  yield takeLatest(FETCH_INSURANCES_BY_STATE, fetchInsurance);
}
