import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { auth } from 'redux/modules/auth';
import { appointment } from 'redux/modules/appointment';
import { provider } from 'redux/modules/provider';
import { state } from 'redux/modules/state';
import { schedule } from 'redux/modules/schedule';
import { conversation } from 'redux/modules/conversation';
import { profile } from 'redux/modules/profile';
import { invoice } from 'redux/modules/invoice';
import { payment } from 'redux/modules/payment';
import { connection } from 'redux/modules/connection';
import { publicConnection } from 'redux/modules/public-connection';
import { chat } from 'redux/modules/chat';
import { chatbot } from 'redux/modules/chatbot';
import { publicChat } from 'redux/modules/public-chat';
import { publicChatbot } from 'redux/modules/public-chatbot';
import { publicConversation } from 'redux/modules/public-conversation';
import { snackbar } from 'redux/modules/snackbar';
import { socket } from 'redux/modules/socket';
import { AppState, IAction } from './types';

export const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['auth'],
};

const appReducer = persistCombineReducers(persistConfig, {
  auth,
  appointment,
  provider,
  state,
  schedule,
  conversation,
  profile,
  invoice,
  payment,
  connection,
  chat,
  chatbot,
  publicChat,
  publicChatbot,
  publicConnection,
  publicConversation,
  // @ts-expect-error: Let's ignore type error
  snackbar,
  socket,
});

export default function rootReducer(state: AppState | any, action: IAction): any {
  return appReducer(state, action);
}
