import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest, signOff } from 'utils/httpClient';

export const loginViaMagicLink = (payload): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.MAGIC_LOGIN, payload);
};

export const publicBookAppointment = (payload): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.PUBLIC_BOOK_APPOINTMENT, payload);
};

export const getRefreshToken = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_REFRESH_TOKEN, undefined);
};

export const checkEmailPhoneUniqueness = (payload): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.CHECK_EMAIL_PHONE_UNIQUENESS, payload);
};
export const saveProvisionalInfo = (payload): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.SAVE_PROVISIONAL_USER, payload);
};

export const logout = (): void => {
  return signOff();
};
